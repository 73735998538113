import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";



const firebaseConfig = {
  apiKey: "AIzaSyBVGLOkeBgLifWKf47IK6Zye4ViSb8aM00",
  authDomain: "news-98275.firebaseapp.com",
  projectId: "news-98275",
  storageBucket: "news-98275.appspot.com",
  messagingSenderId: "1048487701518",
  appId: "1:1048487701518:web:22fa070d60af47dd4ea05f",
  measurementId: "G-5NGQ0VQWMD"
};

const app = initializeApp(firebaseConfig);
const authentication = getAuth();

export {app, authentication};
